import React, { useEffect, useState } from 'react';
import '../Header/Header.css';  // You can keep this if you have other styles
import LiveUpdate from '../../pages/Live Update/LiveUpdate';
import { getLinkData } from '../../server/api';
import { BsAndroid2 } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive'

const Header = () => {
  const [isHoverPlay, setIsHoverPlay] = useState(false);
  const [isHoverDownload, setIsHoverDownload] = useState(false);
  const [count,setCount] = useState(1)
  const [data,setData] = useState()
  const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' })

  // Inline styles for buttons
  const buttonStyle = {
    position:'absolute',
    color: '#fff',
    border: 'none',
    padding: '10px 10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  };
  const buttonStyle2 = {
    position:'absolute',
    right:0,
    color: '#fff',
    border: 'none',
    padding: '10px 10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  };
  const playOnlineStyle = {
    ...buttonStyle,
    background: isHoverPlay ? '#e53e30' : '#f56505', 
    transform: isHoverPlay ? 'scale(1.05)' : 'scale(1)', 
    fontSize: isBigScreen ? '18px' : '12px',
    display:count==1?'none':'block',
    opacity: count === 1 ? 0 : 1, // Use opacity for smooth transition
  visibility: count === 1 ? 'hidden' : 'visible',
  transition: 'opacity 0.5s ease',
    left:0,
    width: isBigScreen ?'120px':'90px',
  };

  const downloadAppStyle = {
    ...buttonStyle2,
    background: isHoverDownload ? '#e53e30' : '#f56505', // Darker shade on hover
    transform: isHoverDownload ? 'scale(1.05)' : 'scale(1)', // Scale up on hover
    fontSize: isBigScreen ? '18px' : '12px',
    right:0,
    width: isBigScreen ?'200px':'120px',
  };
  const fetchMarketData = async () => {
    try {
      const response = await getLinkData();
      if (response) {
        setData(response?.data);

      }
    } catch (error) {
      console.error("Error fetching data data:", error);
    }
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => (prevCount === 1 ? 2 : 1));
    }, 1000); // Slowed down to 5 seconds
  
    return () => clearInterval(interval);
  }, []);
  useEffect(() => { 
    fetchMarketData();
  }, []);
  return (
    <>
      <div className='header-main' >
        <div className='container-fluid'>
          <div className='main-cont'>
            <h1>kalyan 124 Matka</h1>
            <p>!! Top Satta Matka Result Site !!</p>
            <hr style={{ width: '98%', margin: '5px 0' }} />
            <h3>India's Most Popular Matka Result & Tips Site.</h3>
          </div>
        </div>
        <div className="button-container">
          {/* <button
          
            style={playOnlineStyle}
            onMouseEnter={() => setIsHoverPlay(true)}
            onMouseLeave={() => setIsHoverPlay(false)}
            onClick={()=>window.open('https://kalyan-badshah-9ac01.web.app/')}
          >
            Play Online
          </button> */}
          <button
            style={downloadAppStyle}
            onMouseEnter={() => setIsHoverDownload(true)}
            onMouseLeave={() => setIsHoverDownload(false)}
            onClick={()=>window.open(data?.app_link)}
          >
          <BsAndroid2/>  Download App
          </button>
        </div>
        <LiveUpdate data={data} />
      </div>

      <style jsx>{`
        .button-container {
          position: fixed;
          bottom: 45%;
          left: 0;
          right: 0;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
        }

        button {
          padding: 10px 20px;
          font-size: 22px;
          cursor: pointer;
        }

        /* Mobile Styles */
        @media (max-width: 768px) {
          .button-container {
            flex-direction: column;
            align-items: center;
          }

          button {
            width: 100%;
            max-width: 300px;
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
